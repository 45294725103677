import Vue from 'vue';
import Router from 'vue-router';
import { checkPermission } from './utils/middlewares/permission';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/admin/schools',
      component: () => import('@/view/layout/Layout')
    },
    {
      path: '/admin',
      beforeEnter: checkPermission,
      redirect: '/admin/schools',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: 'schools',
          name: 'schools',
          component: () => import('@/view/pages/admin/schools/Schools')
        },
        {
          path: 'instructors',
          name: 'instructors',
          component: () => import('@/view/pages/admin/Instructors')
        },
        {
          path: 'questions',
          name: 'questions',
          component: () => import('@/view/pages/admin/Questions')
        },
        {
          path: 'school/:schoolId',
          name: 'school',
          component: () => import('@/view/pages/admin/school/School.vue'),
          props: true
        },
        {
          path: 'school/:schoolId/panel/:instructorId',
          name: 'instructor',
          component: () =>
            import('@/view/pages/admin/instructor/Instructor.vue'),
          props: true
        },
        {
          path: 'course/:courseId',
          name: 'course',
          component: () => import('@/view/pages/admin/course/Course.vue'),
          props: true
        },
        {
          path: 'student/:studentId',
          name: 'student',
          component: () => import('@/view/pages/admin/student/Student.vue'),
          props: true
        }
      ]
    },
    {
      path: '/panel',
      beforeEnter: checkPermission,
      redirect: '/panel/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: 'dashboard',
          name: 'panel-dashboard',
          component: () => import('@/view/pages/panel/home/Home.vue')
        },
        {
          path: 'school/:schoolId',
          name: 'panel-school',
          component: () => import('@/view/pages/panel/school/School'),
          props: true
        },
        {
          path: 'course/:courseId',
          name: 'panel-course',
          component: () => import('@/view/pages/admin/course/Course.vue'),
          props: true
        },
        {
          path: 'student/:studentId',
          name: 'panel-student',
          component: () => import('@/view/pages/admin/student/Student.vue'),
          props: true
        }
      ]
    },
    {
      path: '/auth/admin',
      redirect: '/auth/admin/login',
      component: () => import('@/view/pages/admin/auth/Auth'),
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import('@/view/pages/admin/auth/Login')
        },
        {
          name: 'register',
          path: 'register',
          component: () => import('@/view/pages/admin/auth/Register')
        }
      ]
    },
    {
      path: '/auth/panel',
      redirect: '/auth/panel/login',
      component: () => import('@/view/pages/panel/auth/Auth'),
      children: [
        {
          name: 'panel-login',
          path: 'login',
          component: () => import('@/view/pages/panel/auth/Login')
        },
        {
          name: 'panel-register',
          path: 'register',
          component: () => import('@/view/pages/panel/auth/Register')
        }
      ]
    },
    {
      path: '/auth',
      redirect: '/auth/admin/login'
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ]
});
