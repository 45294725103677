export default {
  state: {
    toast: {
      visible: false
    }
  },
  getters: {
    toast(state) {
      return state.toast;
    }
  },
  actions: {
    setToast(state, payload) {
      state.commit('setToast', payload);
    }
  },
  mutations: {
    setToast(state, payload) {
      state.toast = { ...state.toast, ...payload, visible: true };
      setTimeout(() => {
        state.toast = { visible: false };
      }, 5000);
    }
  }
};
