import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ApiService from './services/api.service';
//import MockService from "./core/mock/mock.service";
//import { VERIFY_AUTH } from './store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/store/config.module';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from './plugins/vue-i18n';
import vuetify from './plugins/vuetify';
import './plugins/portal-vue';
import './plugins/bootstrap-vue';
import './plugins/perfect-scrollbar';
import './plugins/highlight-js';
import './plugins/inline-svg';
import './plugins/apexcharts';
import './plugins/metronic';
import './plugins/vuelidate-error-extractor';
import './plugins/sweetalert2';
import '@mdi/font/css/materialdesignicons.css';

// Custom Component Registiration
import Card from './view/content/components/Card';
import { toolMixin } from '@/utils/mixins/tool';

Vue.component('card', Card);

// Mixins
Vue.mixin(toolMixin);

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  //Promise.all([store.dispatch(VERIFY_AUTH)]).then();
  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');
