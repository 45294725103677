import { formatDate, toUpperCase } from '@/utils';
import _ from 'lodash';
import { mapMutations } from 'vuex';

export const toolMixin = {
  filters: {
    toUpperCase,
    formatDate
  },
  methods: {
    ...mapMutations(['setLoading']),
    dateToMoment(date) {
      function pad(number) {
        if (number < 10) {
          return '0' + number;
        }
        return number;
      }

      const dbDate = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
      )}`;
      return this.$moment(dbDate);
    },
    _cloneDeep(obj) {
      return _.cloneDeep(obj);
    },
    _find(obj, finder) {
      return _.find(obj, finder);
    },
    confirmChanges() {
      return this.$swal({
        icon: 'warning',
        title: this.$t('notification.swal.saveChanges'),
        showCancelButton: true,
        confirmButtonText: this.$t('common.save'),
        cancelButtonText: this.$t('common.dontSave')
      });
    }
  },
  computed: {
    namespace() {
      return this.$store.getters.namespace;
    }
  }
};
