import moment from 'moment';

export const pad = number => {
  if (number < 10) {
    return '0' + number;
  }
  return number;
};

export const toUpperCase = string => {
  if (string) {
    const letters = { i: 'İ', ş: 'Ş', ğ: 'Ğ', ü: 'Ü', ö: 'Ö', ç: 'Ç', ı: 'I' };
    string = string.replace(/(([iışğüçö]))/g, function(letter) {
      return letters[letter];
    });
    return string.toUpperCase();
  }
};

export function toLowerCase(string) {
  const letters = { İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç' };
  string = string.replace(/(([İIŞĞÜÇÖ]))/g, function(letter) {
    return letters[letter];
  });
  return string.toLowerCase();
}

export const formatDate = (date, mode = 1) => {
  switch (mode) {
    case 1:
      return moment(date).format('DD/MM/YYYY');
    case 2:
      return moment(date).format('D MMMM YYYY');
    case 3:
      return moment(date).format('D/M/YY HH:mm');
    case 4:
      return moment(date).format('HH:mm');
    case 5: // Passed Time in HH:mm Format
      const passedHour = moment().diff(moment(date), 'hours');
      const passedMinute = moment().diff(moment(date), 'minutes');
      const remainingMinutes = passedMinute % 60;
      const passedSeconds = moment().diff(moment(date), 'seconds');
      const remainingSeconds = passedSeconds % 60;
      return (
        pad(passedHour) +
        ':' +
        pad(remainingMinutes) +
        ':' +
        pad(remainingSeconds)
      );
    case 6: // Remaining days
      const remaining = moment(date).diff(moment(), 'days');
      return remaining < 0 ? '-' : remaining;
  }
};

export function range(start, stop, step) {
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step == 'undefined') {
    step = 1;
  }

  if ((step > 0 && start > stop) || (step < 0 && start <= stop)) {
    return [];
  }

  let result = [];
  for (let i = start; step > 0 ? i <= stop : i >= stop; i += step) {
    result.push(i);
  }

  return result;
}
