import Vue from 'vue';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import FormGroup from '@/view/content/components/FormGroup';

const messages = {
  required: 'Bu alan gerekli!',
  email: 'Lütfen geçerli bir eposta adresi girin!',
  minLength: 'En az {min} karakter olmalıdır.',
  maxLength: 'En fazla {max} karakter olmalıdır.',
  numeric: 'Girdiğiniz değer sayılardan oluşmalıdır.'
};

Vue.use(VuelidateErrorExtractor, {
  messages
});

Vue.component('FormGroup', FormGroup);
Vue.component('FormWrapper', templates.FormWrapper);
