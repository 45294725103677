import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

const options = {
  customClass: {
    confirmButton: 'btn btn-primary default btn-lg ml-2 mb-4',
    cancelButton: 'btn btn-light default btn-lg mr-2 mb-4',
    denyButton: 'btn btn-light default btn-lg mr-2 mb-4'
  },
  buttonsStyling: false,
  reverseButtons: true
};
Vue.use(VueSweetalert2, options);
