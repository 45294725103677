import JwtService from '@/services/jwt.service';
import jwt from 'jsonwebtoken';
import { UserTypes } from '@/config/constants';

export async function checkPermission(to, from, next) {
  try {
    const namespace = to.path.split('/')[1];

    const token = JwtService.getToken();
    if (!token) {
      next('/auth/' + namespace + '/login');
      return false;
    }

    const decoded = await jwt.decode(token);
    if (decoded.userType === UserTypes.ADMIN) {
      if (namespace === 'admin') {
        next();
      } else {
        next('/admin/dashboard');
      }
    } else if (decoded.userType === UserTypes.INSTRUCTOR) {
      if (namespace === 'panel') {
        next();
      } else {
        next('/panel/dashboard');
      }
    }
  } catch (e) {
    console.log(e);
  }
}
