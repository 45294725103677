<template>
  <v-col :cols="cols" :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <slot
      :attrs="{ errorMessages: activeErrorMessages, success: isValid }"
      :hasErrors="hasErrors"
      :listeners="listeners"
    />
  </v-col>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';
export default {
  props: ['cols', 'xs', 'sm', 'md', 'lg', 'xl', 'quickValidate'],
  extends: singleErrorExtractorMixin,
  computed: {
    listeners() {
      if (this.quickValidate) {
        return {
          input: () => this.preferredValidator.$touch()
        };
      } else {
        return {
          blur: () => this.preferredValidator.$touch()
        };
      }
    }
  }
};
</script>
